import LogoDefault from "../assets/img/logo.png";
import LogoLight from "../assets/img/logo-white.png";

type Props = {
  lightLogo?: boolean;
};

const Logo = ({ lightLogo }: Props) => (
  <div className="logo_container">
    <img
      className="icon"
      src={lightLogo ? LogoLight : LogoDefault}
      alt="PecanTrust logo"
    />
  </div>
);

export default Logo;
