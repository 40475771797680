import Loader from "./loader";
import Icon from "../assets/svg";

type Props = {
  className?: string;
  btnStyle?: any;
  text: any;
  type?: any;
  leftIcon?: string;
  rightIcon?: string;
  loading?: boolean;
  loadingText?: string;
  secondaryLoader?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
};

const Button = ({
  className,
  btnStyle,
  text,
  leftIcon,
  rightIcon,
  loading,
  loadingText,
  secondaryLoader,
  disabled,
  onClick,
  ...rest
}: Props) => (
  <button
    className={`button_component btn ${className ? className : "btn_primary"} ${
      loading && "loading"
    }`}
    style={btnStyle}
    onClick={onClick}
    disabled={disabled || loading}
    {...rest}
  >
    {loading ? (
      <div className="loader">
        <Loader white={secondaryLoader ? false : true} />
        {loadingText && <p>{loadingText}</p>}
      </div>
    ) : (
      <>
        {leftIcon && (
          <span className="left_icon">
            <Icon name={leftIcon} />
          </span>
        )}
        {text}
        {rightIcon && (
          <span className="right_icon">
            <Icon name={rightIcon} />
          </span>
        )}
      </>
    )}
  </button>
);

export default Button;
