import { combineReducers } from "redux";
import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import componentsSlice from "./components/components-slice";
import userSlice from "./auth/user-slice";
import homeSlice from "./dashboard/home-slice";
import segmentsSlice from "./dashboard/segments-slice";
import insightsSlice from "./dashboard/insights-slice";
import campaignsSlice from "./dashboard/campaigns-slice";
import customersSlice from "./dashboard/customers-slice";
import settingsSlice from "./dashboard/settings-slice";

const reducer = combineReducers({
  // add all reducers here
  componentsSlice,
  userSlice,
  homeSlice,
  segmentsSlice,
  insightsSlice,
  campaignsSlice,
  customersSlice,
  settingsSlice,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production" ? true : false,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
