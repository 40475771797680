import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import moment from "moment";

import PageTitle from "../../components/page-title";
import InsightsTopbar from "./insights-topbar";
import SectionHeader from "../../components/section-header";
import DropDown from "../../components/dropdown";
import Loader from "../../components/loader";
import FormSelect from "../../components/form-select";
import NewCustomInsightModal from "../../components/modal/new-custom-insight-modal";

import {
  getInsightsAction,
  postAddInsightToDbAction,
  getUpdateInsightStatusByIdAction,
} from "../../redux/dashboard/insights-slice";

import { APP_LIST_LAYOUT_LIST } from "../../utils/constants";

import Icon from "../../assets/svg";

const listOrderOptions = [
  { label: "Most recent", value: "desc" },
  { label: "Most oldest", value: "asc" },
];

const InsightsPage = () => {
  const dispatch = useAppDispatch();

  const { listLayout } = useSelector((state: any) => state.componentsSlice);
  const { isFetchingInsights, insights } = useSelector(
    (state: any) => state.insightsSlice
  );

  const [filter, setFilter] = useState("active");
  const [listOrder, setListOrder] = useState(listOrderOptions[0]);
  const [isNewCustomInsightModalOpen, setIsNewCustomInsightModalOpen] =
    useState(false);

  const ACTIVE = "active";
  const IN_PROGRESS = "in-progress";
  const COMPLETED = "completed";
  const REJECTED = "rejected";

  const getInsights = (value?: string) => {
    value && setFilter(value);

    dispatch(getInsightsAction({ status: value }));
  };

  useEffect(() => {
    getInsights(filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postAddInsightToDb = (payload: any) => {
    dispatch(postAddInsightToDbAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getInsights();
        setIsNewCustomInsightModalOpen(false);
      }
    });
  };

  const ACCEPT_INSIGHT_OPTION = {
    label: "Accept",
    action: (id: string) => updateInsightStatus(IN_PROGRESS, id),
  };
  const COMPLETE_INSIGHT_OPTION = {
    label: "Complete",
    action: (id: string) => updateInsightStatus(COMPLETED, id),
  };
  const REJECT_INSIGHT_OPTION = {
    label: "Reject",
    action: (id: string) => updateInsightStatus(REJECTED, id),
  };

  const insightCardOptions = (status: string) => {
    if (status === ACTIVE) {
      return [ACCEPT_INSIGHT_OPTION, REJECT_INSIGHT_OPTION];
    }
    if (status === IN_PROGRESS) {
      return [COMPLETE_INSIGHT_OPTION];
    }
  };

  const updateInsightStatus = (status: string, id: string) => {
    dispatch(getUpdateInsightStatusByIdAction(status, id)).then((res) => {
      if (res?.success === true) {
        setFilter(status);
        getInsights(status);
      }
    });
  };

  const dateDisplay = (endDate: Date) => {
    return `${moment(endDate).format("MMM DD")}`;
  };

  const updateData = (value: string) => {
    setFilter(value);

    getInsights(value);
  };

  const groupedData = () => {
    var grouped: any = insights?.reduce(function (r: any, a: any) {
      r[a.insight_status] = r[a.insight_status] || [];
      r[a.insight_status].push(a);
      return r;
    }, Object.create(null));

    grouped = Object.values(grouped).map((value: any) => ({
      status:
        value[0]?.insight_status === "active"
          ? "to do"
          : value[0]?.insight_status?.replace("-", " "),
      data: value,
    }));

    return grouped;
  };

  const insightsData =
    listOrder?.value === listOrderOptions[0].value
      ? groupedData() || []
      : groupedData()?.map((item: any) => ({
          ...item,
          data: item?.data?.slice()?.reverse() || [],
        })) || [];

  const insightsAvailable = insights?.length > 0;

  return (
    <React.Fragment>
      <PageTitle title="Insights" />
      <InsightsTopbar
        generateCustom={() => setIsNewCustomInsightModalOpen(true)}
        filter={filter}
        onFilter={updateData}
      />

      <div className="page_container insights_page_container">
        {isFetchingInsights && <Loader />}

        {!isFetchingInsights && insightsAvailable && (
          <>
            <div className="top_filters">
              <FormSelect
                name="filterSelect"
                defaultValue={listOrder}
                options={listOrderOptions}
                onChange={(value: any) => setListOrder(value)}
              />
            </div>

            {insightsData?.map((insight: any, index: any) => (
              <React.Fragment key={index}>
                <SectionHeader
                  leftSide={
                    <p className="status" style={{ color: "#2563eb" }}>
                      {insight.status}
                      <span>
                        (
                        {insight.data.length > 9
                          ? insight.data.length
                          : `0${insight.data.length}`}
                        )
                      </span>
                    </p>
                  }
                />

                {listLayout === APP_LIST_LAYOUT_LIST ? (
                  <div className="vertical_scroll">
                    <div className="layout list_layout">
                      {insight.data?.map((item: any, i: any) => (
                        <div key={i} className="item">
                          <div className="title_and_tag">
                            <Link to={`/insights/${item.id}`} className="title">
                              {item.insight_title}
                            </Link>
                          </div>

                          <p className="desc">{item?.insight_details}</p>

                          <div className="date_and_customers">
                            <div className="date">
                              <Icon name="calendar" />
                              {dateDisplay(item.created_at)}
                            </div>
                          </div>

                          <DropDown toggler={<Icon name="dotsH" />}>
                            {insightCardOptions(item?.insight_status)?.map(
                              (option: any, index: any) => (
                                <div
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => option?.action(item.id)}
                                >
                                  {option?.label}
                                </div>
                              )
                            )}
                          </DropDown>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="layout grid_layout">
                    {insight.data?.map((item: any, i: any) => (
                      <div key={i} className="item">
                        <div className="top">
                          <DropDown toggler={<Icon name="dotsH" />}>
                            {insightCardOptions(item?.insight_status)?.map(
                              (option: any, index: any) => (
                                <div
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => option?.action(item.id)}
                                >
                                  {option?.label}
                                </div>
                              )
                            )}
                          </DropDown>
                        </div>

                        <Link to={`/insights/${item.id}`} className="title">
                          {item.insight_title}
                        </Link>

                        <p className="desc">{item?.insight_details}</p>

                        <div className="footer">
                          <div className="date">
                            <Icon name="calendar" />
                            {dateDisplay(item.created_at)}
                          </div>

                          {/* <div className="customers">
                            {item.customers?.map(
                              (customer: any, index: any) => (
                                <img
                                  key={index}
                                  src={customer.image}
                                  alt="Customer avatar"
                                />
                              )
                            )}
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      <NewCustomInsightModal
        isOpen={isNewCustomInsightModalOpen}
        close={() => setIsNewCustomInsightModalOpen(false)}
        onSubmit={(payload) => postAddInsightToDb(payload)}
      />
    </React.Fragment>
  );
};

export default InsightsPage;
