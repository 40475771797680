import { useState, useEffect } from "react";
import moment from "moment";

import Accordion from "../../components/accordion";
import FormSelect from "../../components/form-select";

import Icon from "../../assets/svg";

type Props = {
  insightData: any;
  status?: any;
  onChange?: (value: any) => void;
};

const InsightsDetails = ({ insightData, status, onChange }: Props) => {
  const [selected, setSelected] = useState({});

  const statusOptions = [
    { label: "To Do", value: "to-do" },
    { label: "In Progress", value: "in-progress" },
    { label: "Completed", value: "completed" },
  ];
  useEffect(() => {
    setSelected(statusOptions[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateDisplay = (date: Date) => {
    return `${moment(date).format("MMM DD, YYYY")}`;
  };

  return (
    <div className="insight_details_container">
      <div className="desc_title">
        <p>{insightData.insight_title}</p>

        {onChange && (
          <FormSelect
            name="select"
            defaultValue={selected}
            options={statusOptions}
            onChange={(value: any) => {
              setSelected(value);
              onChange(value.value);
            }}
          />
        )}
      </div>

      <Accordion
        open
        title="Details"
        content={
          <>
            <div className="header">
              <div className="info">
                <p className="title">CREATED AT</p>

                <div className="date">
                  <Icon name="calendar" />
                  {dateDisplay(insightData.created_at)}
                </div>
              </div>
            </div>

            <div className="description">
              <p className="title">Description</p>
              <p className="desc">{insightData.insight_details}</p>

              {/* <Button text="Add Segment" leftIcon="plus" className="btn_text" /> */}
            </div>
          </>
        }
      />
    </div>
  );
};

export default InsightsDetails;
