import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Link } from "react-router-dom";

import PageTitle from "../../components/page-title";
import Table from "../../components/table";
import CustomerTopbar from "./customer-topbar";

import { getCustomersAction } from "../../redux/dashboard/customers-slice";

import { formatNumber, getInitials } from "../../utils/functions";

const listOrderOptions = [
  { label: "Most recent", value: "desc" },
  { label: "Most oldest", value: "asc" },
];

const CustomersPage = () => {
  const dispatch = useAppDispatch();

  const { isFetchingCustomers, customers } = useSelector(
    (state: any) => state.customersSlice
  );

  const [listOrder, setListOrder] = useState(listOrderOptions[0]);

  useEffect(() => {
    dispatch(getCustomersAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHeaders = [
    "Customer Name",
    "Customer Email",
    "Completed Purchases",
  ];

  const customersData =
    listOrder?.value === listOrderOptions[0].value
      ? customers?.data || []
      : customers?.data?.slice()?.reverse() || [];

  return (
    <React.Fragment>
      <PageTitle title="Customers" />
      <CustomerTopbar
        listOrder={listOrder}
        setListOrder={setListOrder}
        options={listOrderOptions}
      />

      <div className="page_container customer_page_container">
        <div className="table_container">
          <Table
            isLoading={isFetchingCustomers}
            headers={tableHeaders}
            noRecord={customers?.count < 1}
          >
            {customersData?.map((row: any, i: any) => (
              <tr key={i}>
                <td>
                  <Link to={`/customers/${row.id}`} className="customer">
                    <div className="image">
                      <p className="initials">
                        {getInitials(
                          row?.customer_data?.first_name +
                            " " +
                            row?.customer_data?.last_name
                        )}
                      </p>
                    </div>
                    <p className="text_wrap name">
                      {row?.customer_data?.first_name +
                        " " +
                        row?.customer_data?.last_name}
                    </p>
                  </Link>
                </td>
                <td>
                  <p className="text_wrap">{row?.customer_data?.email}</p>
                </td>
                <td>
                  <p>${formatNumber(row?.customer_data?.total_spent, 2)}</p>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomersPage;
