import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Link } from "react-router-dom";

import PageTitle from "../../components/page-title";
import SegmentsTopbar from "./segments-topbar";
import Loader from "../../components/loader";
import FormSelect from "../../components/form-select";
import NewCustomSegmentModal from "../../components/modal/new-custom-segment-modal";

import {
  getSegmentsAction,
  postAddSegmentToDbAction,
} from "../../redux/dashboard/segments-slice";

import { hexCodeGenerate } from "../../utils/functions";
import {
  APP_LIST_LAYOUT_GRID,
  APP_LIST_LAYOUT_LIST,
} from "../../utils/constants";

const listOrderOptions = [
  { label: "Most recent", value: "desc" },
  { label: "Most oldest", value: "asc" },
];

const SegmentsPage = () => {
  const dispatch = useAppDispatch();

  const { listLayout } = useSelector((state: any) => state.componentsSlice);
  const { isFetchingSegments, segments } = useSelector(
    (state: any) => state.segmentsSlice
  );

  const [param, setParam] = useState("ai");
  const [listOrder, setListOrder] = useState(listOrderOptions[0]);
  const [isNewCustomSegmentModalOpen, setIsNewCustomSegmentModalOpen] =
    useState(false);

  const getSegments = (value: string) => {
    setParam(value);

    dispatch(getSegmentsAction({ type: value }));
  };

  useEffect(() => {
    getSegments(param);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postAddSegmentToDb = (payload: any) => {
    dispatch(postAddSegmentToDbAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getSegments(param);
        setIsNewCustomSegmentModalOpen(false);
      }
    });
  };

  const segmentsData =
    listOrder?.value === listOrderOptions[0].value
      ? segments || []
      : segments?.slice()?.reverse() || [];

  const segmentsAvailable = segmentsData?.length > 0;

  return (
    <React.Fragment>
      <PageTitle title="Segments" />
      <SegmentsTopbar
        generateCustom={() => setIsNewCustomSegmentModalOpen(true)}
        onFilter={getSegments}
      />

      <div className="page_container segments_page_container">
        {isFetchingSegments && <Loader />}

        {!isFetchingSegments && segmentsAvailable && (
          <>
            <div className="top_filters">
              <FormSelect
                name="filterSelect"
                defaultValue={listOrder}
                options={listOrderOptions}
                onChange={(value: any) => setListOrder(value)}
              />
            </div>

            {listLayout === APP_LIST_LAYOUT_LIST && (
              <div className="vertical_scroll">
                <div className="layout list_layout">
                  {segmentsData?.map((item: any, i: any) => (
                    <div key={i} className="item">
                      <div className="sn_and_title">
                        <p
                          className="sn"
                          style={{ color: `${hexCodeGenerate()}` }}
                        >
                          {i + 1}
                        </p>

                        <Link to={`/segments/${item.id}`} className="title">
                          {item.segment_name}
                        </Link>
                      </div>

                      <p
                        className="info"
                        dangerouslySetInnerHTML={{
                          __html: item.segment_description,
                        }}
                      ></p>

                      <p className="customers">
                        {JSON.parse(item?.customers || [])?.length} customers
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {listLayout === APP_LIST_LAYOUT_GRID && (
              <div className="layout grid_layout">
                {segmentsData?.map((item: any, i: any) => (
                  <div key={i} className="item">
                    <div className="top">
                      <p
                        className="sn"
                        style={{ color: `${hexCodeGenerate()}` }}
                      >
                        {i + 1}
                      </p>
                    </div>
                    <Link to={`/segments/${item.id}`} className="title">
                      {item.segment_name}
                    </Link>

                    <p
                      className="info"
                      dangerouslySetInnerHTML={{
                        __html: item.segment_description,
                      }}
                    ></p>

                    <div className="footer">
                      <p className="customers">
                        {JSON.parse(item?.customers || [])?.length} customers
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <NewCustomSegmentModal
        isOpen={isNewCustomSegmentModalOpen}
        close={() => setIsNewCustomSegmentModalOpen(false)}
        onSubmit={(payload) => postAddSegmentToDb(payload)}
      />
    </React.Fragment>
  );
};

export default SegmentsPage;
