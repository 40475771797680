import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  ArcElement,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import PageTitle from "../../components/page-title";
import Topbar from "../../components/topbar";
import DatePickerComponent from "../../components/datepicker";
import FormSelect from "../../components/form-select";
import Loader from "../../components/loader";

import {
  getDailyVideoAction,
  getNoDailyVideoAction,
  getTopInsightsAction,
  getChartDataAction,
} from "../../redux/dashboard/home-slice";

const chartPlotOptions = [{ label: "Campaigns", value: "campaigns" }];

const DashboardPage = () => {
  const dispatch = useAppDispatch();

  const { dailyVideoSrc, isFetchingInsights, insightsData } = useSelector(
    (state: any) => state.homeSlice
  );

  const [queryDate, setQueryDate] = useState<any>(moment().subtract(1, "days"));
  const [topInsightsData, setTopInsightsData] = useState<any>([]);
  const [chartPlot, setChartPlot] = useState(chartPlotOptions[0]);
  const [chartPlotData, setChartPlotData] = useState<any>({});

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend
  );

  useEffect(() => {
    dispatch(getChartDataAction()).then((res: any) => {
      if (res?.status === true) {
        setChartPlotData(res?.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsData) {
      setTopInsightsData(JSON.parse(insightsData));
    } else {
      setTopInsightsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsData]);

  const getQueryDateData = () => {
    dispatch(getDailyVideoAction(queryDate.format("YYYY-MM-DD"))).then(
      (res) => {
        if (res?.status !== 200) {
          dispatch(getNoDailyVideoAction());
        }
      }
    );

    dispatch(getTopInsightsAction(queryDate.format("YYYY-MM-DD")));
  };
  useEffect(() => {
    getQueryDateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDate]);

  const datePickerDisplayValue = (date: Date) => {
    const moment = require("moment");
    return `${moment(date)?.format("MMM DD, yyyy")}`;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const labels = chartPlotData?.dates || [];

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: chartPlotOptions[0]?.label,
        data: chartPlotData?.values || [],
        borderColor: "#2563EB",
        backgroundColor: "rgba(37, 99, 235, 0.05)",
        lineTension: 0.3,
      },
    ],
  };

  return (
    <React.Fragment>
      <PageTitle title="Dashboard" />
      <Topbar
        title="Dashboard"
        onSearch={() => {}}
        activeBtnFilter={""}
        hideLayoutStyle={true}
      />

      {isFetchingInsights ? (
        <Loader />
      ) : (
        <div className="page_container dash_home_page_container">
          <div className="top_actions">
            <DatePickerComponent
              value={datePickerDisplayValue(queryDate)}
              onChange={(date: any) => setQueryDate(moment(date))}
              range={false}
              maxDate={new Date()}
            />
          </div>

          <div className="grid_layout">
            <div className="video_update">
              <video
                className="video_player"
                src={dailyVideoSrc}
                controls
              ></video>
              <p className="title">
                Daily Video Update for {queryDate.format("MMMM Do, YYYY")}
              </p>
              <p className="text">See what's going on on your Konvas Account</p>
            </div>

            <div className="insights">
              <div className="insight">
                <p className="title">
                  Top Insights for {queryDate.format("MMMM Do, YYYY")}
                </p>

                <div className="list">
                  {topInsightsData?.length > 0 ? (
                    topInsightsData?.map((item: string, i: number) => (
                      <p key={i}>{item}</p>
                    ))
                  ) : (
                    <p>
                      You do not have Top Insights for{" "}
                      {queryDate.format("MMMM Do, YYYY")}. Select a different
                      date or check tomorrow.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="chart_graph_container">
            <div className="top">
              <p>Analytics</p>

              <FormSelect
                name="filterSelect"
                defaultValue={chartPlot}
                placeholder="Select Segment"
                options={chartPlotOptions}
                onChange={(value: any) => setChartPlot(value)}
              />
            </div>

            <div className="chart_container">
              <Line options={options} data={data} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DashboardPage;
