import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError } from "../../utils/functions";

import {
  useLocalStorage,
  getUserDetails,
  logoutUser,
} from "../../utils/functions";
import { APP_USER } from "../../utils/constants";

type Props = {
  isCreatingUser: boolean;
  user: {} | any;
};
export const initialState: Props = {
  isCreatingUser: false,
  user: getUserDetails(),
};

// Slice
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsCreatingUser: (state, { payload }) => {
      state.isCreatingUser = payload;
    },
    loginSuccess: (state, { payload }) => {
      state.user = payload;
      useLocalStorage.set(APP_USER, payload);
    },
    logoutSuccess: (state) => {
      state.user = null;
      logoutUser();
    },
  },
});
export default slice.reducer;

// Actions
const { setIsCreatingUser, loginSuccess, logoutSuccess } = slice.actions;

export const createUserAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingUser(true));

    const res = await postRequest({
      url: API_URLS?.createUser,
      data,
      params: null,
      formData: true,
    });

    dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsCreatingUser(false));
    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingUser(false));
  }
};

export const loginUserAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingUser(true));

    const res = await postRequest({
      url: API_URLS?.loginUser,
      data,
      params: null,
      formData: true,
    });

    dispatch(loginSuccess({ ...res?.data?.token_data, ...res?.data?.data }));
    dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsCreatingUser(false));
    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingUser(false));
  }
};

export const googleLoginAction =
  (data: any, isLogin?: boolean) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.googleLogin,
        params: null,
        data,
        formData: false,
      });

      if (res?.data?.user_exists === true) {
        dispatch(
          loginSuccess({ ...res?.data?.token_data, ...res?.data?.data })
        );
        dispatch(setAlert(true, "success", "Login successful!"));
      } else if (isLogin) {
        dispatch(
          setAlert(
            true,
            "error",
            "Account does not exist! Kindly proceed to Sign up."
          )
        );
      }

      return {
        success: res?.data?.status,
        user_exists: res?.data?.user_exists,
      };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const createUserWithGoogleAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.googleRegister,
        params: null,
        data,
        formData: false,
      });

      dispatch(loginSuccess({ ...res?.data?.token_data, ...res?.data?.data }));
      dispatch(setAlert(true, "success", res?.data?.message));

      return { success: res?.data?.status };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const shopifyLoginAction = (slug: string) => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: `${API_URLS?.shopifyLogin}/${slug}`,
      params: null,
    });

    if (res?.data?.status === true) {
      dispatch(loginSuccess({ ...res?.data?.token_data, ...res?.data?.data }));
      dispatch(setAlert(true, "success", res?.data?.message));
    } else {
      dispatch(setAlert(true, "error", res?.data?.message));
    }

    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const forgotPasswordAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingUser(true));

    // const res = await postRequest({
    //   url: API_URLS?.loginUser,
    //   data,
    //   params: null,
    //   formData: null,
    // });

    dispatch(setIsCreatingUser(false));
    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingUser(false));
  }
};

export const logoutUserAction = () => async (dispatch: any) => {
  try {
    await getRequest({
      url: API_URLS?.logoutUser,
      params: null,
    });

    dispatch(logoutSuccess());
  } catch (error) {
    const message = getRequestError(error);
    console.log(message);
  }
};

export const updateProfileAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.updateProfile,
        params: null,
        data,
        formData: false,
      });

      dispatch(
        setAlert(
          true,
          res?.data?.status === true ? "success" : "error",
          res?.data?.message
        )
      );
      if (res?.data?.status === true) {
        const userState = getState().userSlice.user;
        dispatch(
          loginSuccess({ ...userState, ...data, user_name: data?.name })
        );
      }
      return { success: res?.data?.status };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const updatePasswordAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.changePassword,
      params: null,
      data,
      formData: false,
    });

    dispatch(
      setAlert(
        true,
        res?.data?.status === true ? "success" : "error",
        res?.data?.message
      )
    );
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getSubscriptionAction = (id: string) => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: `${API_URLS?.subscription}/${id}`,
      params: null,
    });

    return { success: res?.data?.status, data: res?.data?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getPlansAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.plans,
      params: null,
    });

    return { success: res?.data?.status, data: res?.data?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const upgradePlanAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.upgradePlan,
      params: null,
      data,
      formData: false,
    });

    dispatch(
      setAlert(
        true,
        res?.data?.status === true ? "success" : "error",
        res?.data?.message
      )
    );
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};
