import React, { useState } from "react";
import classNames from "classnames";

import Icon from "../assets/svg";

type Props = {
  open?: boolean;
  title: string;
  content: React.ReactNode;
};

const Accordion = ({ open, title, content }: Props) => {
  const [isOpen, setIsOpen] = useState(open ? true : false);

  return (
    <div
      className={classNames("accordion_container", {
        open: isOpen,
      })}
    >
      <div className="accordion_header" onClick={() => setIsOpen(!isOpen)}>
        <p className="title">{title}</p>
        <Icon name="chevronDown" />
      </div>

      <div className="accordion_content">{content}</div>
    </div>
  );
};

export default Accordion;
