import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import moment from "moment";
import classNames from "classnames";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import CampaignReport from "./campaign-report";

import PageTitle from "../../components/page-title";
import Topbar from "../../components/topbar";
import Button from "../../components/button";
import Loader from "../../components/loader";
import DropDown from "../../components/dropdown";
import FormInput from "../../components/form-input";
import SectionHeader from "../../components/section-header";
import NewCampaignModal from "../../components/modal/new-campaign-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import CampaignAIAgentModal from "../../components/modal/campaign-ai-agent-modal";

import {
  getCampaignsAction,
  getCampaignsByIdAction,
  postAddCampaignAction,
  deleteCampaignAction,
  rewriteCampaignMsgAction,
  sendCampaignEmailAction,
  postUpdateCampaignAction,
  getCampaignEmailAnalyticsAction,
} from "../../redux/dashboard/campaigns-slice";

import Icon from "../../assets/svg";

import { APP_LIST_LAYOUT_LIST } from "../../utils/constants";
import { dateToFromNow } from "../../utils/functions";

const listOrderOptions = [
  { label: "Most recent", value: "desc" },
  { label: "Oldest", value: "asc" },
];

const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Active", value: "active" },
  { label: "Completed", value: "completed" },
];

const CampaignsPage = () => {
  const dispatch = useAppDispatch();

  const { listLayout } = useSelector((state: any) => state.componentsSlice);
  const {
    isFetchingCampaigns,
    campaigns,
    isFetchingCampaignData,
    isDeletingCampaign,
    isStartingCampaign,
  } = useSelector((state: any) => state.campaignsSlice);

  const [campaignsData, setCampaignsData] = useState(campaigns || []);
  const [listOrder, setListOrder] = useState(listOrderOptions[0]);
  const [status, setStatus] = useState(statusOptions[0].value);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isRewritingMsg, setIsRewritingMsg] = useState(false);
  const [msgSubject, setMsgSubject] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [isEditingMsgContent, setIsEditingMsgContent] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [newCampaignModal, setNewCampaignModal] = useState(false);
  const [isCampaignAiAgentModalOpen, setIsCampaignAiAgentModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const editor = useRef(null);

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };

  const setCampaignInitialSubjectAndContent = () => {
    setMsgSubject(
      selectedCampaign?.campaign_subject?.replace(
        "[Customer Name]",
        "{$customerName}"
      )
    );
    setMsgContent(msgContentRevamp(selectedCampaign?.campaign_body));
  };

  const sumUpAnalyticsKey = (arr: any, key: string) => {
    return arr?.reduce((a: any, b: any) => a + (b[key] || 0), 0);
  };
  const getCampaignAnalytics = () => {
    dispatch(getCampaignEmailAnalyticsAction(selectedCampaign.id)).then(
      (res: any) => {
        const data: any = res?.data;

        const reached = sumUpAnalyticsKey(data, "sent");
        const delivered = sumUpAnalyticsKey(data, "delivered");
        const bounced =
          sumUpAnalyticsKey(data, "soft_bounced") +
          sumUpAnalyticsKey(data, "hard_bounced");
        const opened = sumUpAnalyticsKey(data, "opened");
        const unsubscribed = sumUpAnalyticsKey(data, "unsubscribed");

        setAnalytics({
          reached,
          delivered,
          bounced,
          opened,
          unsubscribed,
        });
      }
    );
  };

  useEffect(() => {
    setIsEditingMsgContent(false);
    setCampaignInitialSubjectAndContent();

    if (selectedCampaign?.status === "active") {
      getCampaignAnalytics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  useEffect(() => {
    setCampaignsData(campaigns?.filter((x: any) => x.status === status));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, status]);

  const topBarFilters: any = [
    {
      type: "select",
      value: listOrder,
      options: listOrderOptions,
      onChange: (value: any) => setListOrder(value),
    },
  ];

  const getCampaigns = (value?: string) => {
    // if (value === "custom") {
    //   setSegmentData([]);
    // } else {
    //   dispatch(getCampaignsAction());
    // }

    dispatch(getCampaignsAction());
  };

  useEffect(() => {
    getCampaigns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCampaignById = (id: string) => {
    dispatch(getCampaignsByIdAction(id)).then((res: any) => {
      if (res?.success === true) {
        setSelectedCampaign(res?.data);
      }
    });
  };

  const postAddCampaign = (data: { segment_id: string; payload: any }) => {
    dispatch(postAddCampaignAction(data)).then((res) => {
      if (res?.success === true) {
        getCampaigns();
        setNewCampaignModal(false);

        return res;
      }
    });
  };

  const updateCampaign = async () => {
    setIsRewritingMsg(true);

    const payload = {
      id: selectedCampaign?.id,
      campaign_subject: msgSubject,
      campaign_body: msgContent,
    };

    await dispatch(postUpdateCampaignAction(payload)).then((res) => {
      if (res?.success === true) {
        getCampaignById(selectedCampaign?.id);
      }
    });

    setIsRewritingMsg(false);
  };

  const startCampaign = () => {
    const payload = {
      campaign_id: selectedCampaign?.id,
      campaign_subject: msgSubject,
      campaign_body: msgContent,
      campaign_recipients: JSON.parse(selectedCampaign?.campaign_recipients),
    };

    dispatch(sendCampaignEmailAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setStatus(statusOptions[1].value);
        setSelectedCampaign(null);
        getCampaignById(selectedCampaign?.id);
      }
    });
  };

  const deleteCampaign = () => {
    dispatch(deleteCampaignAction(selectedCampaign.id)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setIsConfirmationModalOpen(false);
        setSelectedCampaign(null);
      }
    });
  };

  const groupedData = () => {
    var grouped: any = campaignsData
      ?.map((x: any) => ({
        ...x,
        createdAt: new Date(
          x.status === "draft" ? x.created_at : x.start_date
        ).toLocaleDateString(),
      }))
      ?.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      ?.reduce(function (r: any, a: any) {
        r[a.createdAt] = r[a.createdAt] || [];
        r[a.createdAt].push(a);
        return r;
      }, Object.create(null));

    grouped = Object.values(grouped).map((value: any) => ({
      createdAt: value[0]?.createdAt || "",
      data: value || [],
    }));

    return grouped;
  };

  const handleSelectRow = (rowId: any) => {
    if (selectedRows.some((x) => rowId.includes(x))) {
      setSelectedRows(selectedRows?.filter((x: string) => x !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const isRowSelected = (rowId: any) => {
    return selectedRows.some((x) => rowId.includes(x));
  };

  const contentOptions = [
    { label: "Edit", action: () => setIsEditingMsgContent(true) },
    { label: "Rewrite", action: () => rewriteMsg() },
  ];

  const updateMsgContentAnchorElements = (string: string) => {
    if (string) {
      // Define the regex pattern to match an anchor element and its content
      const anchorRegex =
        /<a\s+([^>]*\s+)?href=['"]([^'"]+)['"]([^>]*)>([^<]+)<\/a>/g;

      // Use the replace method to modify each match
      const updatedString = string?.replace(
        anchorRegex,
        (match, p1, href, p3, text) => {
          const newHref = `${href}/k-{$customerEmail}`;
          const targetAttribute = /target=['"]_blank['"]/.test(match)
            ? ""
            : ' target="_blank"';
          return `<a ${p1 ? p1.trim() + " " : ""
            }href="${newHref}"${targetAttribute}${p3 ? " " + p3.trim() : ""
            }>${text}</a>`;
        }
      );

      return updatedString || "";
    }
  };
  const msgContentRevamp = (msg: any) => {
    var value = msg
      ?.replace(/\\n/g, "\n\n")
      ?.replace(/\\n \\n/g, "\n\n\n")
      ?.replace("```html\n", "")
      ?.replace("\n```", "")
      ?.replace("[Customer Name]", "{$customerName}");
    value = updateMsgContentAnchorElements(value);

    return value || "";
  };

  const rewriteMsg = async () => {
    if (!isRewritingMsg) {
      setIsRewritingMsg(true);

      await dispatch(rewriteCampaignMsgAction(selectedCampaign?.id)).then(
        (res) => {
          if (res?.success === true) {
            setMsgContent(msgContentRevamp(res?.data));
          }
        }
      );

      setIsRewritingMsg(false);
    }
  };

  const dateDisplay = (startDate: Date, endDate: Date) => {
    return `${moment(startDate).format("MMM DD, YYYY") +
      " - " +
      moment(endDate).format("MMM DD, YYYY")
      }`;
  };

  const sunEditorOptions = {
    fontSize: [14, 16, 20, 24, 26],
    buttonList: [
      [
        "undo",
        "redo",
        "fontSize",
        "fontColor",
        "hiliteColor",
        "bold",
        "italic",
        "underline",
      ],
      ["align", "list", "image", "link", "codeView"],
    ],
  };

  const campaignsDataToShow =
    listOrder?.value === listOrderOptions[0].value
      ? groupedData() || []
      : groupedData().reverse() || [];

  return (
    <React.Fragment>
      <PageTitle title="Campaigns" />
      <Topbar
        title="Campaigns"
        onSearch={() => { }}
        filters={topBarFilters}
        actionBtn={
          <Button
            text="New Campaign"
            leftIcon="plus"
            onClick={() => setNewCampaignModal(true)}
          />
        }
      />

      <div className="page_container campaign_page_container">
        {isFetchingCampaigns && <Loader />}

        {!isFetchingCampaigns && (
          <div
            className={classNames("campaign_page_container_inner", {
              is_fetching_campaign_data: isFetchingCampaignData,
            })}
          >
            <div
              className={classNames("campaigns_list", {
                is_visible: !selectedCampaign,
              })}
            >
              <div className="status_sections">
                <div className="side">
                  {statusOptions?.map((item: any, i: any) => (
                    <p
                      key={i}
                      className={classNames("status", {
                        active: item.value === status,
                      })}
                      onClick={() => setStatus(item.value)}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>

                <div className="side">
                  <div className="icon" onClick={() => getCampaigns()}>
                    <Icon name="refresh" />
                  </div>
                </div>
              </div>

              {listLayout === APP_LIST_LAYOUT_LIST ? (
                <div className="layout list_layout">
                  {campaignsDataToShow?.map((campaign: any, index: any) => (
                    <React.Fragment key={index}>
                      <p className="date_title">
                        {dateToFromNow(campaign.createdAt)}
                      </p>

                      <div className="list">
                        {campaign?.data?.map((row: any, i: any) => (
                          <div
                            key={i}
                            className={classNames("row", {
                              active: selectedCampaign?.id === row.id,
                            })}
                          >
                            <div className="left">
                              <FormInput
                                id="selectRow"
                                type="checkbox"
                                value={isRowSelected(row?.campaign_id)}
                                onChange={() =>
                                  handleSelectRow(row?.campaign_id)
                                }
                                readOnly={isFetchingCampaigns}
                              />

                              <p
                                className="title"
                                onClick={() => getCampaignById(row?.id)}
                              >
                                {row.campaign_title}
                              </p>
                            </div>

                            <p className="time">
                              {moment(row.created_at).format("HH:MM A")}
                            </p>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <div className="layout grid_layout">
                  {campaignsData?.map((row: any, i: any) => (
                    <div
                      key={i}
                      className={classNames("row", {
                        active: selectedCampaign?.id === row.id,
                      })}
                    >
                      <div className="row_inner">
                        <div className="top">
                          <p
                            className="title"
                            onClick={() => getCampaignById(row?.id)}
                          >
                            {row.campaign_title}
                          </p>

                          <p className="time">
                            {moment(row.created_at).format("MMM DD")}
                          </p>
                        </div>

                        <p className="subject">{row.campaign_subject}</p>
                        <p className="content">{row.campaign_body}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className={classNames("campaign_details", {
                is_visible: isFetchingCampaignData || selectedCampaign,
              })}
            >
              {isFetchingCampaignData ? (
                <Loader />
              ) : (
                selectedCampaign && (
                  <>
                    {selectedCampaign.status !== "draft" && (
                      <CampaignReport
                        reportData={{
                          ...analytics,
                          ...selectedCampaign,
                        }}
                        close={() => setSelectedCampaign(null)}
                      />
                    )}

                    <SectionHeader
                      goBack={true}
                      goBackOnClick={() => setSelectedCampaign(null)}
                      title="Campaign Message"
                      printer
                    />

                    <div className="header">
                      <div className="side">
                        <div className="product">
                          <p>
                            Title:{" "}
                            <span>{selectedCampaign?.campaign_title}</span>
                          </p>
                          <div className="dot"></div>
                        </div>

                        <div className="product">
                          <p>
                            AI Agent:{" "}
                            <span
                              className="link"
                              onClick={() =>
                                setIsCampaignAiAgentModalOpen(true)
                              }
                            >
                              View Agent's Analysis, Rationale and
                              Recommendations for this Campaign
                            </span>
                          </p>
                          <div className="dot"></div>
                        </div>

                        <div className="product">
                          <p>
                            To:{" "}
                            <span>
                              {
                                JSON.parse(
                                  selectedCampaign?.campaign_recipients
                                )?.length
                              }{" "}
                              recipients
                            </span>
                          </p>
                          <div className="dot"></div>
                        </div>
                      </div>

                      <div className="side">
                        {/* <div className="icon" onClick={() => {}}>
                          <Icon name="arrowForward" />
                        </div>
                        <div className="icon" onClick={() => {}}>
                          <Icon name="star" />
                        </div> */}
                      </div>
                    </div>

                    <div className="content">
                      {selectedCampaign.status === "draft" && (
                        <div className="top">
                          <div
                            className="regenerate"
                            onClick={() => rewriteMsg()}
                          >
                            <Icon name="regenerate" />
                            Rewrite message?
                          </div>

                          <div className="date">
                            <Icon name="calendar" />
                            {dateDisplay(
                              new Date(selectedCampaign?.start_date),
                              new Date(selectedCampaign?.end_date)
                            )}
                          </div>
                        </div>
                      )}

                      <div className="body">
                        {selectedCampaign.status === "draft" && (
                          <div className="actions">
                            <DropDown toggler={<Icon name="dotsH" />}>
                              {contentOptions?.map((item: any, i: any) => (
                                <div
                                  key={i}
                                  className="dropdown-item"
                                  onClick={item?.action}
                                >
                                  {item?.label}
                                </div>
                              ))}
                            </DropDown>
                          </div>
                        )}

                        {isRewritingMsg ? (
                          <Loader />
                        ) : (
                          <div className="body_content">
                            {!isEditingMsgContent ? (
                              <p className="subject">{msgSubject}</p>
                            ) : (
                              <FormInput
                                type="text"
                                placeholder="Subject"
                                value={msgSubject}
                                readOnly={false}
                                onChange={(e) => setMsgSubject(e.target.value)}
                              />
                            )}

                            {!isEditingMsgContent ? (
                              <div className="message">
                                <div
                                  className="message_text"
                                  dangerouslySetInnerHTML={{
                                    __html: msgContent,
                                  }}
                                ></div>
                              </div>
                            ) : (
                              <>
                                <SunEditor
                                  getSunEditorInstance={getSunEditorInstance}
                                  name="message"
                                  placeholder="Type your message here..."
                                  setOptions={sunEditorOptions}
                                  defaultValue={msgContent}
                                  onChange={(content) => setMsgContent(content)}
                                />

                                <div className="actions">
                                  <Button
                                    text="Cancel"
                                    className="btn_secondary"
                                    onClick={() => {
                                      setIsEditingMsgContent(false);
                                      setCampaignInitialSubjectAndContent();
                                    }}
                                  />
                                  <Button
                                    text="Save"
                                    onClick={updateCampaign}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {selectedCampaign.status === "draft" &&
                      !isEditingMsgContent && (
                        <div className="footer">
                          <div className="schedule" onClick={() => { }}>
                            <Icon name="clock" />
                            Schedule for Later
                          </div>

                          <div className="side">
                            <div
                              className="icon"
                              onClick={() => setIsConfirmationModalOpen(true)}
                            >
                              <Icon name="trash" />
                            </div>

                            <DropDown toggler={<Icon name="dotsV" />}>
                              {contentOptions?.map((item: any, i: any) => (
                                <div
                                  key={i}
                                  className="dropdown-item"
                                  onClick={item?.action}
                                >
                                  {item?.label}
                                </div>
                              ))}
                            </DropDown>

                            <Button
                              text="Start Now"
                              onClick={startCampaign}
                              loading={isStartingCampaign}
                            />
                          </div>
                        </div>
                      )}
                  </>
                )
              )}
            </div>
          </div>
        )}
      </div>

      <NewCampaignModal
        isOpen={newCampaignModal}
        close={() => setNewCampaignModal(false)}
        onSubmit={(payload) => postAddCampaign(payload)}
      />

      <CampaignAIAgentModal
        isOpen={isCampaignAiAgentModalOpen}
        close={() => setIsCampaignAiAgentModalOpen(false)}
        aiDetails={selectedCampaign?.ai_details}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        confirmationText={`Are you sure you want to delete ${selectedCampaign?.campaign_title}?`}
        onSubmit={deleteCampaign}
        loading={isDeletingCampaign}
      />
    </React.Fragment>
  );
};

export default CampaignsPage;
