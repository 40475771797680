import SectionHeader from "../../components/section-header";
import Accordion from "../../components/accordion";

import Icon from "../../assets/svg";

type Props = {
  reportData: any;
  close: () => void;
};

const CampaignReport = ({ reportData, close }: Props) => {
  const dateDisplay = (date: Date) => {
    return `${new Date(date).toDateString()}`;
  };

  const startDate: any = new Date(reportData?.start_date).getTime();
  const today: any = new Date().getTime();

  const hoursDiff = Math.abs(today - startDate) / 36e5;
  const showAnalytics = hoursDiff > 24 ? true : false;

  return (
    <div className="campaign_report">
      <SectionHeader
        title="Campaign Report"
        rightSide={
          <div className="icon" onClick={close}>
            <Icon name="close" />
          </div>
        }
      />

      <div className="report_content">
        {showAnalytics ? (
          <div className="bottom">
            <Accordion
              open
              title="SUMMARY"
              content={
                <>
                  <div className="header">
                    <div className="info">
                      <p className="title">REACHED</p>
                      <p className="value">{reportData?.reached}</p>
                    </div>

                    <div className="info">
                      <p className="title">START DATE</p>

                      <div className="date">
                        <Icon name="calendar" />
                        {dateDisplay(reportData.start_date)}
                      </div>
                    </div>

                    <div className="info">
                      <p className="title">OPEN RATE</p>
                      <p className="value">
                        {Math.round(
                          (reportData?.opened * 100) / reportData?.reached
                        )}
                        %
                      </p>
                    </div>

                    <div className="info">
                      <p className="title">END DATE</p>

                      <div className="date">
                        <Icon name="calendar" />
                        {dateDisplay(reportData.end_date)}
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <Accordion
              open
              title="CAMPAIGN PERFORMANCE"
              content={
                <div className="header">
                  <div className="info">
                    <p className="title">DELIVERED</p>
                    <p className="value">{reportData?.delivered}</p>
                  </div>

                  <div className="info">
                    <p className="title">BOUNCED</p>
                    <p className="value">{reportData?.bounced}</p>
                  </div>

                  <div className="info">
                    <p className="title">OPENED</p>
                    <p className="value">{reportData?.opened}</p>
                  </div>

                  <div className="info">
                    <p className="title">UNSUBSCRIBED</p>
                    <p className="value">{reportData?.unsubscribed}</p>
                  </div>
                </div>
              }
            />
          </div>
        ) : (
          <p className="check_back">
            Your campaign was sent successfully, kindly check back in{" "}
            <span>
              {Math.round(24 - hoursDiff)}
              hours
            </span>
            .
          </p>
        )}
      </div>
    </div>
  );
};

export default CampaignReport;
