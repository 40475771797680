import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import FormSelect from "./form-select";

import Icon from "../assets/svg";

type Props = {
  params: {
    page: number;
    limit: number;
  };
  pageCount: number;
  changeData: ({ limit, page }: { limit: number; page: number }) => void;
};

const Pagination = ({ params, pageCount, changeData }: Props) => {
  const [selected, setSelected] = useState({});

  const handlePaginate = ({ limit, page }: { limit?: any; page?: number }) => {
    changeData({
      limit: limit !== undefined ? parseInt(limit.value) : params.limit,
      page: page !== undefined ? page : params.page,
    });
  };

  const rowOptions = [
    { label: "6 items", value: "6" },
    { label: "12 items", value: "12" },
    { label: "25 items", value: "25" },
    { label: "50 items", value: "50" },
  ];
  useEffect(() => {
    setSelected(rowOptions[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pagination_container">
      <div className="rows">
        <p>Showing rows:</p>

        <FormSelect
          name="select"
          defaultValue={selected}
          options={rowOptions}
          onChange={(value: any) => {
            setSelected(value);
            handlePaginate({ limit: value });
          }}
        />
      </div>

      <ReactPaginate
        previousLabel={<Icon name="chevronDown" />}
        nextLabel={<Icon name="chevronDown" />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={(value) => handlePaginate({ page: value?.selected })}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};

export default Pagination;
