import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ProtectedRoute } from "./components/protected-route";
import DashboardPages from "./dashboard/dashboard";

import SignInPage from "./pages/auth/sign-in";
import SignUpPage from "./pages/auth/sign-up";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";

import Alert from "./components/alert";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const { alert } = useSelector((state: any) => state.componentsSlice);

  const DummyPage = () => {
    return <>Hello</>;
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/verify" component={DummyPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <ProtectedRoute component={DashboardPages} />
        </Switch>
      </BrowserRouter>

      {/* Other components */}
      {alert?.show && (
        <Alert className={alert?.type} text={alert?.message} close />
      )}
    </React.Fragment>
  );
};

export default App;
