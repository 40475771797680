import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/page-title";
import CustomerTopbar from "./customer-topbar";
import Table from "../../components/table";

import {
  getCustomersByIdAction,
  getCustomerInsightAction,
} from "../../redux/dashboard/customers-slice";

import { lightBulb, purchases } from "../../assets/img";

import { customersInfoPurchaseData } from "../../utils/mockdata";
import { formatNumber, getInitials } from "../../utils/functions";

import Loader from "../../components/loader";

const CustomersInfoPage = () => {
  const query: { id: string } = useParams();
  const dispatch = useAppDispatch();

  const {
    isFetchingCustomerData,
    customerData,
    isFetchingCustomerInsight,
    customerInsight,
  } = useSelector((state: any) => state.customersSlice);

  const [tableData, setTableData] = useState<any>({});

  useEffect(() => {
    dispatch(getCustomersByIdAction(query.id));
    dispatch(getCustomerInsightAction(query.id));
    setTableData(customersInfoPurchaseData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHeaders = ["Product name", "Price", "Fulfillment status"];

  return (
    <React.Fragment>
      <PageTitle title="Customer Info" />
      <CustomerTopbar />

      <div className="page_container customer_info_page_container">
        {isFetchingCustomerData && <Loader />}

        {!isFetchingCustomerData && (
          <>
            <div className="customer_info_page_inner">
              <div className="top_section">
                <div className="customer">
                  <div className="image">
                    <p className="initials">
                      {getInitials(
                        customerData?.customer_data?.first_name +
                          " " +
                          customerData?.customer_data?.last_name
                      )}
                    </p>
                  </div>
                  <p>
                    {customerData?.customer_data?.first_name +
                      " " +
                      customerData?.customer_data?.last_name}
                  </p>
                </div>

                <div className="info">
                  <img src={purchases} alt="Purchases icon" />

                  <div className="side">
                    <p className="title">Total Spend</p>
                    <p className="amount">
                      $
                      {formatNumber(
                        customerData?.customer_data?.total_spent,
                        2
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="insight">
                <img src={lightBulb} alt="Insights icon" />

                <div className="side">
                  <p className="title">Customer Insights</p>

                  <p
                    className={`${
                      isFetchingCustomerInsight ? "generating" : "details"
                    }`}
                  >
                    {isFetchingCustomerInsight
                      ? "Generating... Please wait"
                      : customerInsight?.insight_details}
                  </p>
                </div>
              </div>

              <p className="history">Purchase History</p>

              <div className="table_container">
                <Table
                  isLoading={isFetchingCustomerData}
                  headers={tableHeaders}
                  noRecord={tableData?.count < 1}
                >
                  {customerData?.customer_orders?.map((row: any, i: any) => (
                    <React.Fragment key={i}>
                      {row?.line_items?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>
                            <div className="product">
                              <p className="text_wrap">{item?.name}</p>
                            </div>
                          </td>
                          <td>
                            <p>${formatNumber(item?.price, 2)}</p>
                          </td>
                          <td>
                            <p>{item?.fulfillment_status || "- - -"}</p>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </Table>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomersInfoPage;
