import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import classNames from "classnames";

import Loader from "../../components/loader";
import Button from "../../components/button";

import { setAlert } from "../../redux/components/components-slice";
import {
  getSubscriptionAction,
  getPlansAction,
  upgradePlanAction,
} from "../../redux/auth/user-slice";

import { formatNumber } from "../../utils/functions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const Subscription = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.userSlice);

  const [isFetching, setIsFetching] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<any>({});
  const [plansData, setPlansData] = useState([]);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [period, setPeriod] = useState("Monthly");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getSubscription();
    getPlans();

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const billing_type = params.get("billing_type");
    const package_id = params.get("package_id");
    const payment = params.get("payment");

    if (billing_type) {
      handleUpgradeOnSuccess({
        billing_type,
        package_id,
      });
    } else if (payment === "error") {
      dispatch(
        setAlert(true, "error", "Error processing plan upgrade transaction!")
      );
      history.push("/settings?tab=subscription");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscription = async () => {
    setIsFetching(true);

    await dispatch(getSubscriptionAction(user?.userId)).then((res) => {
      if (res?.success === true) {
        setSubscriptionData(res?.data);
      }
    });

    setIsFetching(false);
  };
  const getPlans = async () => {
    setIsFetching(true);

    await dispatch(getPlansAction()).then((res) => {
      if (res?.success === true) {
        setPlansData(res?.data);
      }
    });

    setIsFetching(false);
  };

  const handleCheckout = async (plan: any) => {
    const stripe = await stripePromise;
    if (!stripe) {
      console.error("Stripe.js has not loaded");
      return;
    }

    setIsSubmitting(true);

    const baseUrl = window.location.origin + window.location.pathname;

    const payload = new FormData();
    payload.append("priceId", plan?.stripeId);
    payload.append("customerEmail", user?.email);
    payload.append("customerName", user?.user_name || user?.company_name);
    payload.append(
      "successUrl",
      `${baseUrl}?tab=subscription&billing_type=${period?.toLowerCase()}&package_id=${
        plan?.id
      }`
    );
    payload.append("cancelUrl", `${baseUrl}?tab=subscription&payment=error`);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscription/stripe_checkout`,
      payload
    );

    const session = await response.data?.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      setIsSubmitting(false);
      dispatch(setAlert(true, "error", result.error.message));
    }
  };

  const handleUpgradeOnSuccess = (data: any) => {
    setIsFetching(true);

    const payload = {
      ...data,
      user_id: user?.userId,
      payment_id: subscriptionData?.puid,
      payment_method: "stripe",
    };

    dispatch(upgradePlanAction(payload)).then((res) => {
      if (res?.success === true) {
        history.push("/settings?tab=subscription");
        getSubscription();
      } else {
        setIsFetching(false);
      }
    });
  };

  const periods = ["Monthly", "Yearly"];

  const today = moment(new Date());
  const expires = moment(subscriptionData?.expire_on);
  const daysDifference = expires.diff(today, "days");

  return (
    <div className="content_container">
      <div
        className={classNames("form subscription_form", {
          upgrade_form: showUpgrade,
        })}
      >
        <p className="title">{showUpgrade ? "Upgrade Plan" : "Subscription"}</p>

        {isFetching ? (
          <Loader />
        ) : showUpgrade ? (
          <div className="upgrade">
            <div className="periods">
              {periods.map((item: string, i: number) => {
                const isActive = item === period;

                return (
                  <p
                    key={i}
                    className={isActive ? "active" : ""}
                    onClick={() => setPeriod(item)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
            <a
              className="page_link"
              href="https://konvas.ai/pricing"
              target="_blank"
              rel="noreferrer"
            >
              See Pricing page
            </a>

            <div className="plans">
              {plansData?.map((item: any, i: number) => {
                const isMonthly = period === periods[0];
                const isActive = item?.slug === subscriptionData?.slug;
                const yearlyMonthPrice = parseInt(item?.price) / 12;
                const stripeId = isMonthly
                  ? item?.stripe_month_id
                  : item?.stripe_year_id;
                const planKey = `${item?.name?.toLowerCase()}_plan`;

                return (
                  <div key={i} className="item">
                    <p className="name">{item?.name}</p>
                    <p className="price">
                      $
                      {isMonthly
                        ? item?.monthly_price
                        : formatNumber(yearlyMonthPrice, 2)}
                      <span>/month</span>
                    </p>
                    <div className="features">
                      {item?.features?.map((feature: any, index: number) => (
                        <p key={index}>
                          <span>
                            {feature?.[planKey]
                              ? formatNumber(feature?.[planKey], 0)
                              : 0}{" "}
                          </span>
                          {feature?.feature_name}
                        </p>
                      ))}
                    </div>

                    <Button
                      text={isActive ? "Current Plan" : "Upgrade"}
                      className={isActive ? "btn_secondary" : ""}
                      onClick={() => handleCheckout({ ...item, stripeId })}
                      disabled={!stripeId ? true : isActive ? true : false}
                      loading={isActive ? false : isSubmitting}
                    />
                  </div>
                );
              })}
            </div>

            <Button
              text="Cancel"
              className="btn_tertiary"
              onClick={() => setShowUpgrade(false)}
            />
          </div>
        ) : (
          <>
            <div className="table">
              <p>
                Plan<span>{subscriptionData?.package_name}</span>
              </p>
              <p>
                Price<span>${subscriptionData?.amount}</span>
              </p>
              <p>
                Billing Cycle
                <span className="capitalise">
                  {subscriptionData?.billing_type}
                </span>
              </p>
              <p>
                Last Billing
                <span>
                  {moment(subscriptionData?.created_at).format("MMM DD, YYYY")}
                </span>
              </p>
              <p>
                Expires
                <span>
                  {moment(subscriptionData?.expire_on).format("MMM DD, YYYY")} (
                  {daysDifference} days left)
                </span>
              </p>
              <p>
                Payment Status
                <span className="status">{subscriptionData?.status}</span>
              </p>
            </div>

            <div className="action">
              <Button text="View Plans" onClick={() => setShowUpgrade(true)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscription;
