import { avatar } from "../assets/img";

export const didYouKnowTexts = [
  "Did you know? AI-powered campaigns can increase conversion rates by up to 50% through personalized content recommendations tailored to individual user preferences.",
  "Did you know that AI-driven campaigns can significantly reduce marketing costs by automating tasks such as audience segmentation and ad optimization, leading to more efficient budget allocation?",
  "Did you know? With AI, campaigns can be continuously optimized in real-time based on user engagement data, ensuring that marketing efforts remain relevant and effective.",
  "Did you know that AI-powered campaigns can analyze vast amounts of consumer data to predict future trends and behaviors, enabling businesses to stay ahead of the competition with proactive marketing strategies?",
  "Did you know? AI-driven campaigns can enhance customer experiences by delivering personalized interactions across multiple channels, fostering deeper brand engagement and loyalty.",
  "Did you know that AI algorithms can analyze social media sentiments and trends to inform campaign strategies, helping brands connect with their audience on a more emotional level?",
  "Did you know? AI-powered campaigns can identify and target niche audiences with precision, allowing businesses to tailor their messaging to specific demographics and interests for maximum impact.",
  "Did you know that AI-generated insights from campaign performance data can provide valuable feedback for continuous improvement, helping marketers refine their strategies and achieve better results over time?",
  "Did you know? AI can automate A/B testing processes for campaign elements such as email subject lines or ad creatives, enabling marketers to quickly identify the most effective variations for optimal performance.",
  "Did you know that AI-powered campaigns can analyze competitor activities and market trends in real-time, empowering businesses to adapt their marketing strategies swiftly and capitalize on emerging opportunities?",
];

export const segmentsData = [
  {
    id: "1",
    title: "Tech-savvy bargain hunters",
    age: "25-45",
    income: "Middle class to efficient",
    interests: "Technology, gadgets, saving money",
    behavior:
      "Research products extensively before buying, shop around for the best deals, frequently use coupons and promo codes",
    startDate: new Date(),
    endDate: new Date(),
    customers: 102,
  },
  {
    id: "6y0w",
    title: "Health-conscious millennials",
    age: "20-35",
    income: "Varies",
    interests: "Fitness, healthy eating, organic products",
    behavior:
      "Read ingredient labels carefully, prioritize products with natural ingredients, willing to pay a premium for healthier options",
    startDate: new Date(),
    endDate: new Date(),
    customers: 291,
  },
  {
    id: "vf6w",
    title: "Fashion-forward trendsetters",
    age: "18-40",
    income: "Varies",
    interests: "Fashion, social media, flow with trends",
    behavior:
      "Follow fashion influencers, shop at trendy boutiques and online retailers, experiment with different styles",
    startDate: new Date(),
    endDate: new Date(),
    customers: 188,
  },
  {
    id: "357u",
    title: "Eco-friendly consumers",
    age: "Varies",
    income: "Varies",
    interests: "Sustainability, environmentalism, living an eco-conscious lifestyle",
    behavior:
      "Look for products with minimal packaging, choose sustainable brands, prioritize reusable items",
    startDate: new Date(),
    endDate: new Date(),
    customers: 85,
  },
  {
    id: "sds9",
    title: "Homebody DIY enthusiasts",
    age: "30-65",
    income: "Middle class to efficient",
    interests: "Home improvement, DIY projects, spending time at home",
    behavior:
      "Enjoy hands-on projects, watch DIY tutorials online, frequent home improvement stores",
    startDate: new Date(),
    endDate: new Date(),
    customers: 108,
  },
  {
    id: "pal0",
    title: "Active outdoor adventurers",
    age: "Varies",
    income: "Middle class to efficient",
    interests: "Hiking, camping, kayaking, other outdoor activities",
    behavior:
      "Prioritize gear and apparel that is durable, functional, and comfortable for outdoor activities",
    startDate: new Date(),
    endDate: new Date(),
    customers: 192,
  },
];

export const segmentInfoData = {
  id: "190w",
  name: "Tech-savvy bargain hunters",
  age: "25-45",
  income: "Middle class to efficient",
  interests: "Technology, gadgets, saving money",
  behavior:
    "Research products extensively before buying, shop around for the best deals, frequently use coupons and promo codes",
  campaigns: [
    {id: "rg67", name: "New product launch", favorite: true},
    {id: "f676", name: "Black friday", favorite: false},
    {id: "tyjt", name: "Leveraging Philadephia opportunity", favorite: true},
    {id: "76tt", name: "Laptop crosselling to Samsung phone", favorite: false},
  ],
  insights: [
    {id: "656h", name: "Highlight Sustainablility"},
    {id: "il98", name: "Increasing campaign budget by 20%"},
    {id: "uilu", name: "Create Tiktok to expand reach to target audience"},
  ],
};

export const segmentInfoTableData = {
  count: 21,
  totalPages: 21,
  data: [
    {id: "o8ds", customer: { id: "nsuud1", name: "Mei Lin" }, purchase: 399.82},
    {id: "0w8q", customer: { id: "nsuud1", name: "Diego Rodriguez" }, purchase: 2998.00},
    {id: "3efi", customer: { id: "nsuud1", name: "Yusuf Musa" }, purchase: 30082.00},
    {id: "ud8s", customer: { id: "nsuud1", name: "Andrei Ivanov" }, purchase: 4010.00},
    {id: "brew", customer: { id: "nsuud1", name: "Aya Takahashi" }, purchase: 110.00},
    {id: "64ht", customer: { id: "nsuud1", name: "Adedapo Tunde" }, purchase: 253.00},
    {id: "trh7", customer: { id: "nsuud1", name: "Gabriela Silva" }, purchase: 211.00},
    {id: "k907", customer: { id: "nsuud1", name: "Isabella Rossi" }, purchase: 567.00},
    {id: "rtn7", customer: { id: "nsuud1", name: "Lars Andersen" }, purchase: 1043.00},
  ]
};

export const insightsData = [
  {
    id: "1",
    title: "Highlight Sustainablility",
    status: { name: "to do", color: "#F6A723" },
    tag: { name: "Eco-friendly consumers", color: "#38BDF8" },
    desc: "Amplify sustainability: champion eco-friendly choices, prioritize renewable resources, and foster a greener mindset for a resilient and thriving future.",
    endDate: new Date(),
    customers: [{ name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }],
  },
  {
    id: "rerg",
    title: "Increasing campaign",
    status: { name: "to do", color: "#F6A723" },
    tag: { name: "Tech-savvy bargain hunters", color: "#2563EB" },
    desc: "Leverage email marketing's power: engage, inform, and convert with targeted campaigns, building lasting connections and driving business growth effectively.",
    endDate: "2023-10-21",
    customers: [{ name: "John Doe", image: avatar }],
  },
  {
    id: "rw56",
    title: "Partner with Brands",
    status: { name: "completed", color: "#F6A723" },
    tag: { name: "Active outdoor adventurers", color: "#24D164" },
    desc: "Collaborate strategically: partner with brands to amplify reach, enhance credibility, and unlock mutually beneficial opportunities for sustained success.",
    endDate: "2024-01-10",
    customers: [{ name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }],
  },
  {
    id: "6yu8",
    title: "Loyalty Program",
    status: { name: "completed", color: "#2563EB" },
    tag: { name: "Tech-savvy bargain hunters", color: "#2563EB" },
    desc: "Cultivate loyalty: establish a rewarding program, enticing customers with exclusive perks, fostering lasting connections, and driving brand allegiance.",
    endDate: "2023-12-26",
    customers: [{ name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }],
  },
  {
    id: "gh67",
    title: "Partner with Influencers",
    status: { name: "rejected", color: "#2563EB" },
    tag: { name: "Tech-savvy bargain hunters", color: "#2563EB" },
    desc: "Elevate brand influence: strategically partner with influencers to authentically connect with audiences, drive engagement, and amplify your market presence.",
    endDate: "2023-11-12",
    customers: [{ name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }, { name: "John Doe", image: avatar }],
  },
];

export const colorCodes = ["#24D164", "#F6A723", "#2563EB", "#24D164", "#F6A723", "#2563EB", "#24D164", "#F6A723", "#2563EB"];

export const insightInfoData = {
  id: "190w",
  title: "Highlight Sustainablility",
  status: { name: "to do", color: "#F6A723" },
  tag: { name: "Eco-friendly consumers", color: "#38BDF8" },
  desc: "Amplify sustainability: champion eco-friendly choices, prioritize renewable resources, and foster a greener mindset for a resilient and thriving future.",
  startDate: new Date(),
  endDate: new Date(),
  customers: [
    { name: "Mei Lin", image: avatar },
    { name: "Diego Rodriguez", image: avatar },
    { name: "Yusuf Musa", image: avatar },
    { name: "Andrei Ivanov", image: avatar },
    { name: "Aya Takahashi", image: avatar }
  ],
  campaigns: [
    {id: "rg67", name: "New product launch", favorite: false},
    {id: "76tt", name: "Laptop crosselling to Samsung phone", favorite: false},
  ],
  insights: [
    {id: "656h", name: "Eco-friendly consumers", color: "#38BDF8"},
    {id: "il98", name: "Tech-savvy bargain hunters", color: "#2563EB"},
    {id: "uilu", name: "Active outdoor adventurers", color: "#24D164"},
  ],
  segment: [
    {id: "656h", name: "Eco-friendly consumers", color: "#38BDF8"},
    {id: "il98", name: "Active outdoor adventurers", color: "#24D164"},
  ],
  actions: [
    {id: "656h", name: "Send Campaign to Customers highlighting the sustainability credentials of your products and services in your marketing materials."},
    {id: "er56", name: "Send Campaign to Customers highlighting the sustainability credentials of your products and services in your marketing materials."},
    {id: "90dd", name: "Send Campaign to Customers highlighting the sustainability credentials of your products and services in your marketing materials."},
  ],
};

export const campaignsData = [
  {
    id: "190w",
    title: "New product launch",
    status: "draft",
    subject: "Don't miss out on our latest tech deals!",
    content: "As a tech-savvy bargain hunter, we know you're always on the lookout for the latest and greatest tech deals. That's why we're excited to share why we're excited to share why we're excited to share why we're excited to share.",
    updatedAt: new Date(),
  },
  {
    id: "frrf4",
    title: "Health-conscious millennials",
    status: "active",
    subject: "Fuel your healthy lifestyle with our organic cream.",
    content: "We know how important it is to eat healthy and live a sustainable lifestyle. That's why we're proud to offer a wide selection of organic products that.",
    updatedAt: new Date(),
  },
  {
    id: "54t4",
    title: "Leveraging Philadephia opportunity",
    status: "completed",
    subject: "Stay ahead of the curve with our latest deals.",
    content: "As a fashion-forward trendsetter, you know that staying ahead of the curve is essential. That's why we're constantly updating our selection of trendy.",
    updatedAt: new Date(),
  },
  {
    id: "r434r",
    title: "Laptop crosselling to Samsung phone",
    status: "draft",
    subject: "Make a difference for the planet with our product!",
    content: "We know that you care about the environment and want to make a difference for the planet. That's why we're committed to offering a wide selection of.",
    updatedAt: "2024-01-04",
  },
  {
    id: "76jj76",
    title: "Infinite Impact",
    status: "active",
    subject: "Fuel your healthy lifestyle with our organic cream.",
    content: "We know how important it is to eat healthy and live a sustainable lifestyle. That's why we're proud to offer a wide selection of organic products that.",
    updatedAt: "2024-01-04",
  },
  {
    id: "ujykyu",
    title: "Revolutionize Your World",
    status: "active",
    subject: "Don't miss out on our latest tech deals!",
    content: "As a tech-savvy bargain hunter, we know you're always on the lookout for the latest and greatest tech deals. That's why we're excited to share why we're excited to share why we're excited to share why we're excited to share.",
    updatedAt: "2024-01-01",
  },
  {
    id: "j76j6",
    title: "Ignite the Future",
    status: "completed",
    subject: "Stay ahead of the curve with our latest deals.",
    content: "As a fashion-forward trendsetter, you know that staying ahead of the curve is essential. That's why we're constantly updating our selection of trendy.",
    updatedAt: "2023-12-05",
  },
  {
    id: "h6h7h6",
    title: "Illuminate Your Story",
    status: "active",
    subject: "Make a difference for the planet with our product!",
    content: "We know that you care about the environment and want to make a difference for the planet. That's why we're committed to offering a wide selection of.",
    updatedAt: "2023-12-05",
  },
];

export const campaignReportData = {
  startDate: "12-05-2024",
  endDate: new Date(),
  customers: [
    { name: "John Doe", image: avatar },
    { name: "John Doe", image: avatar },
    { name: "John Doe", image: avatar },
    { name: "John Doe", image: avatar },
    { name: "John Doe", image: avatar }
  ],
  segment: [
    {id: "656h", name: "Eco-friendly consumers", color: "#38BDF8"}
  ],
};

export const campaignTemplates = [
  { id: "848s", title: "Tech-savvy bargain hunters", content: `Hi [Customer Name],\n\n Tech-savvy bargain hunters, unlock unbeatable deals! Harness the power of digital tools and savvy strategies for smarter savings. Your next great find awaits!\n\n For a limited time, you can save up to 50% on a wide selection of tech products, including laptops, smartphones, tablets, and more.\n\n So what are you waiting for? Shop now and save big on the tech you love!` },
  { id: "5ggg", title: "Eco-friendly consumers", content: `Hi [Customer Name],\n\n Join the movement! Eco-friendly consumers, make a positive impact with every purchase. Choose sustainability, embrace green living, and be the change our planet needs. #GreenChoices\n\n For a limited time, you can save up to 50% on a wide selection of tech products, including laptops, smartphones, tablets, and more.\n\n So what are you waiting for? Shop now and save big on the tech you love!` },
  { id: "grth6", title: "Fashion-forward trendsetters", content: `Hi [Customer Name],\n\n Step into style! Fashion-forward trendsetters, lead the way with the latest trends. Elevate your wardrobe, express your unique flair, and inspire the world. Unleash your fashion statement now!\n\n For a limited time, you can save up to 50% on a wide selection of tech products, including laptops, smartphones, tablets, and more.\n\n So what are you waiting for? Shop now and save big on the tech you love!` },
  { id: "gfdr5", title: "Plane-food bargain hunters", content: `Hi [Customer Name],\n\n Fly & savor savings! Plane-food bargain hunters, unlock tasty deals at 30,000 feet. Upgrade your in-flight experience without breaking the bank. Elevate your journey with delicious discounts!\n\n For a limited time, you can save up to 50% on a wide selection of tech products, including laptops, smartphones, tablets, and more.\n\n So what are you waiting for? Shop now and save big on the tech you love!` },
  { id: "rth7", title: "Health-conscious millenials", content: `Hi [Customer Name],\n\n Empower your well-being! Health-conscious millennials, fuel your lifestyle with mindful choices. From nutritious snacks to fitness trends, prioritize your health journey today. #WellnessRevolution\n\n For a limited time, you can save up to 50% on a wide selection of tech products, including laptops, smartphones, tablets, and more.\n\n So what are you waiting for? Shop now and save big on the tech you love!` },
];

export const customersData = {
  count: 21,
  totalPages: 21,
  data: [
    {id: "o8ds", customer: { image: avatar, name: "Mei Lin" }, segment: "Active outdoor adventurers", purchase: 399.82},
    {id: "0w8q", customer: { image: avatar, name: "Diego Rodriguez" }, segment: "Eco-friendly consumers", purchase: 2998.00},
    {id: "3efi", customer: { image: avatar, name: "Yusuf Musa" }, segment: "Tech-savvy bargain hunters", purchase: 30082.00},
    {id: "ud8s", customer: { image: avatar, name: "Andrei Ivanov" }, segment: "Active outdoor adventurers", purchase: 4010.00},
    {id: "brew", customer: { image: avatar, name: "Aya Takahashi" }, segment: "Eco-friendly consumers", purchase: 110.00},
    {id: "64ht", customer: { image: avatar, name: "Adedapo Tunde" }, segment: "Tech-savvy bargain hunters", purchase: 253.00},
    {id: "trh7", customer: { image: avatar, name: "Gabriela Silva" }, segment: "Active outdoor adventurers", purchase: 211.00},
    {id: "k907", customer: { image: avatar, name: "Isabella Rossi" }, segment: "Eco-friendly consumers", purchase: 567.00},
    {id: "rtn7", customer: { image: avatar, name: "Lars Andersen" }, segment: "Tech-savvy bargain hunters", purchase: 1043.00},
  ]
};

export const customersInfoData = {
  image: avatar,
  name: "Angela Leu",
  completedPurchases: 10566.01  ,
  segments: [
    {id: "656h", name: "Active outdoor adventurers",},
    {id: "il98", name: "Eco-friendly consumers"},
    {id: "uilu", name: "Tech-savvy bargain hunters"},
  ],
};

export const customersInfoPurchaseData = {
  count: 21,
  totalPages: 21,
  data: [
    {id: "o8ds", product: { image: "https://istore.com.ng/cdn/shop/products/11black_800x.png?v=1619669158", name: "iPhone" }, brand: "Apple", stock: 100, sales: 1234, price: 399.82, active: true},
    {id: "0w8q", product: { image: "https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/73438f5c-8fa8-4bd2-9676-ac206c90b691/air-max-1-mens-shoes-2C5sX2.png", name: "Air Max" }, brand: "Nike", stock: 2, sales: 500, price: 2998.00, active: true},
    {id: "3efi", product: { image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_MwFzOOQrYDT0CS6DgBjO6KTS1yeV1sGotA&usqp=CAU", name: "Coca-Cola" }, brand: "Coca-Cola", stock: 110, sales: 789, price: 30082.00, active: false},
    {id: "ud8s", product: { image: "https://www-konga-com-res.cloudinary.com/w_auto,f_auto,fl_lossy,dpr_auto,q_auto/media/catalog/product/P/A/153608_1630692301.jpg", name: "ThinkPad" }, brand: "Lenovo", stock: 100, sales: 1234, price: 4010.00, active: true},
    {id: "brew", product: { image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB40-38BPhvZ0HsHI4MBkMOi5G1MxsPpNmF8B-6W8ZnUef5lkDNaVS&usqp=CAE&s", name: "Model S" }, brand: "Tesla", stock: 2, sales: 500, price: 110.00, active: true},
    {id: "64ht", product: { image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKn0sWJoMtTLC9xIIOCTiL8nT_RKrwEfsWjPwaxXrsr9TbHOxKhF6X&usqp=CAE&s", name: "Keurig" }, brand: "Keurig", stock: 110, sales: 789, price: 253.00, active: false},
    {id: "trh7", product: { image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNEXeK9wFuXeNz_gCdQmDhYDvygNxi8tO_afLYNZBtUroGRDYriqZ6&usqp=CAE&s", name: "QuietComfort" }, brand: "Bose", stock: 100, sales: 1234, price: 211.00, active: true},
    {id: "k907", product: { image: "https://ng.jumia.is/unsafe/fit-in/300x300/filters:fill(white)/product/68/2317032/1.jpg?1484", name: "Under Armour" }, brand: "Under Armour", stock: 2, sales: 500, price: 567.00, active: true},
    {id: "rtn7", product: { image: "https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/94/540928/1.jpg?6345", name: "PlayStation" }, brand: "Sony", stock: 110, sales: 789, price: 1043.00, active: false},
  ]
};
