import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/page-title";
import InsightsTopbar from "./insights-topbar";
import SectionHeader from "../../components/section-header";
import Loader from "../../components/loader";
import ConfirmationModal from "../../components/modal/confirmation-modal";

import InsightsDetails from "./insight-details";

import {
  getInsightsByIdAction,
  deleteInsightsAction,
} from "../../redux/dashboard/insights-slice";

import { goBackAction } from "../../utils/functions";
import Icon from "../../assets/svg";

const InsightInfoPage = () => {
  const query: { id: string } = useParams();
  const dispatch = useAppDispatch();

  const { isFetchingInsightData, insightData, isDeletingInsight } = useSelector(
    (state: any) => state.insightsSlice
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getInsightsByIdAction(query.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInsight = () => {
    dispatch(deleteInsightsAction(insightData.id)).then((res: any) => {
      if (res?.success === true) {
        goBackAction();
      }
    });
  };

  return (
    <React.Fragment>
      <PageTitle title="Insight Info" />
      <InsightsTopbar />

      <div className="page_container insights_info_page_container">
        {isFetchingInsightData && <Loader />}

        {!isFetchingInsightData && (
          <>
            <SectionHeader
              goBack
              title={insightData.insight_title}
              rightSide={
                <>
                  <div
                    className="icon"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Icon name="trash" />
                  </div>
                </>
              }
            />

            <div className="content">
              <div className="right_side">
                <InsightsDetails insightData={insightData} />
              </div>
            </div>
          </>
        )}
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        confirmationText={`Are you sure you want to delete ${insightData?.insight_title}?`}
        onSubmit={deleteInsight}
        loading={isDeletingInsight}
      />
    </React.Fragment>
  );
};

export default InsightInfoPage;
